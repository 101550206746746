import { financeManagerFeatures } from '../../../apps/financeManager/featureFlags/financeManagerFeatures';

const { isFA536UiOptimization } = financeManagerFeatures;
const FinancialRoutes = {
    Api: {
        CUSTOMERS: '/customers',
        CATTLE_FAXES: '/cattlefax',
        FEEDBILLS: '/feedBills',
        FEEDLOTS: '/feedlots',
        HEDGE_CONTRACTS: '/hedgeContracts',
        HEDGE_PURCHASES: '/hedgePurchases',
        HEDGE_SALES: '/hedgeSales',
        INTEREST_RATE_CHANGES: '/interestRateChanges',
        LOT_ACTIONS: '/lotActions',
        LOTS: '/lots',
        RE_LOTS: '/relots',
        SETTINGS: '/settings',
        CUSTOMERSETTINGS: 'customersettings/all',
        CUSTOMERSETTINGHISTORY: 'customersettings/history',
        DEFAULTSETTING: 'customersettings/default',
        CUSTOMERSETTING: 'customersettings',
        NEWCUSTOMERSETTING: 'customersettings/custom',
        STATEMENT: '/statement',
        PROJECT_BREAKEVEN_REPORT: 'Lots/reports/breakeven',
    },

    App: {
        HEDGES: '/hedges',
        EDIT_HEDGE: isFA536UiOptimization ? '/hedges/editHedge' : '/editHedge',
        EDIT_HEDGE_WITH_PARAM: isFA536UiOptimization
            ? '/hedges/editHedge/:hedgeId'
            : '/editHedge/:hedgeId',
        CREATE_OPEN_HEDGE: '/createOpenHedge',
        CREATE_CLOSE_HEDGE: '/createCloseHedge',

        CREATE_OWNER: '/createOwner',

        CUSTOMER_CLOSEOUT: isFA536UiOptimization
            ? '/lots/customerCloseout'
            : '/customerCloseout',
        CUSTOMER_CLOSEOUT_WITH_PARAM: isFA536UiOptimization
            ? '/lots/customerCloseout/:lotId'
            : '/customerCloseout/:lotId',
        LENDER_REPORT: isFA536UiOptimization
            ? '/lots/lenderReport'
            : '/lenderReport',
        OWNER_REPORT: isFA536UiOptimization
            ? '/lots/ownerReport'
            : '/ownerReport',
        SHAWNA_SPREADSHEET: isFA536UiOptimization
            ? '/lots/lotCashFlow'
            : '/lotCashFlow',

        FEEDLOTS: '/feedlots',
        CREATE_FEEDLOT: '/createFeedlot',
        FEEDLOT: isFA536UiOptimization ? '/feedlots/feedlot' : '/feedlots',
        FEEDLOT_WITH_PARAM: isFA536UiOptimization
            ? '/feedlots/feedlot/:feedlotId'
            : '/feedlot/:feedlotId',

        LOTS: '/lots',
        CREATE_LOT: '/createLot',
        LOT: '/lots/lot',
        LOT_WITH_PARAM: '/lots/lot/:lotId',
        LOT_BALANCE_REPORT: '/lots/lotBalancesReport',
        LOT_SUMMARY_REPORT: '/lots/lotSummaryReport',
        QUARTERLY_REPORT: '/lots/quarterlyReport',
        PROJECT_BREAKEVEN_REPORT: '/lots/projectedbreakevenreport',

        CREATE_RE_LOT: '/createReLot',
        EDIT_RE_LOT: isFA536UiOptimization ? '/lots/editReLot' : '/editReLot',
        EDIT_RE_LOT_WITH_PARAM: isFA536UiOptimization
            ? 'lots/editReLot/:reLotId'
            : '/editReLot/:reLotId',

        FEEDBILLS: '/feedBills',
        CREATE_FEEDBILL: '/createFeedBill',
        FEEDBILL: '/feedBill',
        EDIT_FEEDBILL: isFA536UiOptimization
            ? '/feedBill/editFeedBill'
            : '/editFeedBill',
        EDIT_FEEDBILL_WITH_PARAM: isFA536UiOptimization
            ? '/feedBill/editFeedBill/:feedBillId'
            : '/editFeedBill/:feedBillId',

        CREATE_CATTLE_PURCHASE: '/createCattlePurchase',
        EDIT_CATTLE_PURCHASE: isFA536UiOptimization
            ? '/lots/editCattlePurchase'
            : '/editCattlePurchase',
        EDIT_CATTLE_PURCHASE_WITH_PARAM: isFA536UiOptimization
            ? '/lots/editCattlePurchase/:cattlePurchaseId'
            : '/editCattlePurchase/:cattlePurchaseId',

        CREATE_CATTLE_SALE: '/createCattleSale',
        EDIT_CATTLE_SALE: isFA536UiOptimization
            ? '/lots/editCattleSale'
            : '/editCattleSale',
        EDIT_CATTLE_SALE_WITH_PARAM: isFA536UiOptimization
            ? '/lots/editCattleSale/:cattleSaleId'
            : '/editCattleSale/:cattleSaleId',

        CREATE_CATTLE_WEIGHT: isFA536UiOptimization
            ? '/lots/createCattleWeight'
            : 'createCattleWeight',
        EDIT_CATTLE_WEIGHT: isFA536UiOptimization
            ? '/lots/editCattleWeight'
            : '/editCattleWeight',
        EDIT_CATTLE_WEIGHT_WITH_PARAM: isFA536UiOptimization
            ? '/lots/editCattleWeight/:cattleWeightId'
            : '/editCattleWeight/:cattleWeightId',

        CREATE_CATTLE_DEAD: isFA536UiOptimization
            ? '/lots/createCattleDead'
            : '/createCattleDead',
        EDIT_CATTLE_DEAD: isFA536UiOptimization
            ? '/lots/editCattleDead'
            : '/editCattleDead',
        EDIT_CATTLE_DEAD_WITH_PARAM: isFA536UiOptimization
            ? '/lots/editCattleDead/:cattleDeadId'
            : '/editCattleDead/:cattleDeadId',

        HEDGE_SALE: '/hedgeSales',
        HEDGE_CONTRACTS: '/hedgecontracts',

        STATEMENTS: '/statements',

        CATTLE_FAXES: '/cattlefaxes',

        INTEREST_RATE_CHANGES: '/interestRateChanges',

        SETTINGS: '/settings',
    },
};

export default FinancialRoutes;
